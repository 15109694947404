import React, { useState } from "react"
import { getRoomInfo, options, rooms } from "../data/rooms"
import "react-dates/initialize"
import { DateRangePicker } from "react-dates"
import "react-dates/lib/css/_datepicker.css"
import { useMobileDevice } from "../utils/isMobileDevice"
import moment from "moment"
import { formatDate } from "../utils"
import { Layout } from "../components/layout"
import { Head } from "../components/commons"

import { ReservationPackage, ReservationSubmitted } from "../components/booking"

import SliderRange from "../components/commons/SliderRange"
import { ErrorMessage } from "../components/commons"
import { nanoid } from "nanoid"
import * as Yup from "yup"
import { Formik, Field, Form } from "formik"

async function postData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  })
  return response.json()
}

const formatEmailMessage = packages => {
  let message = {}
  const getPackageInfo = ({
    stayLength,
    rooms,
    option,
    room,
    price,
    checkIn,
    checkOut,
  }) => {
    return ` ${rooms} ${option} ${room} room for ${stayLength} night(s) (${checkIn}- ${checkOut}) at USD ${price}`
  }

  packages.forEach((el, index) => {
    const newPackage = getPackageInfo(el)

    const key = `Package ${index + 1}`
    message[key] = newPackage
  })

  return message
}

const getStayLength = (startDate, endDate) => {
  if (startDate && endDate) {
    const checkIn = moment(startDate)
    const checkOut = moment(endDate)
    const stayLength = checkOut.diff(checkIn, "days")

    return stayLength > 0 ? stayLength : null
  }

  return null
}

const getPackagePrice = ({
  selectedOption,
  roomsPerPackage,
  pricing,
  stayLength,
}) => {
  return Number(pricing[selectedOption] * stayLength * roomsPerPackage)
}

const getReservationTotal = packages => {
  return packages.length
    ? packages
        .map(reservationPackage => reservationPackage.price)
        .reduce((acc, curr) => acc + curr)
    : 0
}

const submitForm = async ({
  values,
  url = "https://formspree.io/f/moqbvyar",
  onSuccess,
  onFailure,
}) => {
  postData(url, values)
    .then(data => {
      onSuccess && onSuccess(data)
    })
    .catch(err => onFailure && onFailure(err))
}

const ReserveRoom = ({}) => {
  const [selectedRoom, setSelectedRoom] = useState("deluxe")
  const [selectedOption, setSelectedOption] = useState("double")

  const [focusedInput, setFocusedInput] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [roomsPerPackage, setRoomsPerPackage] = useState(1)

  const [packages, setPackages] = useState([])

  const [isOpen, setOpen] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [submissionError, setSubmissionError] = useState("")
  const [submitted, setSubmitted] = useState(false) // Value set to true for testing purposr only

  const validationSchema = Yup.object().shape({
    names: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  })

  const info = getRoomInfo(selectedRoom || "deluxe")
  let stayLength = getStayLength(startDate, endDate)
  const pricing = info.pricing
  const isMobile = useMobileDevice()

  const onDatesChange = ({ startDate, endDate }) => {
    startDate && setStartDate(startDate)
    endDate && setEndDate(endDate)
  }

  const showAddRoomPackageButton = () =>
    Boolean(startDate && endDate && stayLength)

  const resetRoomPackage = () => {
    setStartDate(null)
    setEndDate(null)
    stayLength = null
  }

  const addRoomPackage = () => {
    if (stayLength) {
      let packageInfo = {
        id: nanoid(),
        rooms: roomsPerPackage,
        room: selectedRoom,
        option: selectedOption,
        price: getPackagePrice({
          stayLength,
          roomsPerPackage,
          selectedOption,
          pricing,
        }),
        checkIn: formatDate(startDate),
        checkOut: formatDate(endDate),
        stayLength,
      }

      setPackages([...packages, packageInfo])
      resetRoomPackage()
    }
  }

  const removeRoomPackage = packageID => {
    const remainingPackages = packages.filter(
      roomPackage => roomPackage.id !== packageID
    )

    setPackages(remainingPackages)
  }

  const shouldShowAddPackageButton = showAddRoomPackageButton()

  // Render Reservation Submitted UI

  if (submitted) {
    return <ReservationSubmitted />
  }

  return (
    <Layout>
      <Head title="Reserve rooms" image={{ src: "/images/rooms.jpg" }} />
      <Formik
        initialValues={{ names: "", email: "", phone: "", message: "" }}
        validationSchema={validationSchema}
        onSubmit={async values => {
          setSubmitting(true)
          setSubmissionError("")
          const formData = {
            "Customer Names": values.names,
            "Customer Email": values.email,
            ...formatEmailMessage(packages),
            "Customer Phone": values.phone,
            "Reservation Total": `USD ${Number(
              getReservationTotal(packages)
            ).toLocaleString()}`,
            "Special request": values.message,
          }

          console.log(formData)

          await submitForm({
            values: formData,
            onSuccess: data => {
              setSubmitting(false)
              setSubmitted(true)
            },
            onFailure: err => {
              setSubmitting(false)
              setSubmissionError(
                "Something went wrong. Check your network connection then try again."
              )
            },
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,

          /* and other goodies */
        }) => {
          return (
            <Form>
              <div class=" mt-16  flex items-start lg:items-center justify-center min-h-screen  px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  class={` inline-block  ${
                    isOpen ? "align-top " : "align-middle"
                  } bg-white  rounded-lg text-left  shadow-xl transform  min-w-lg transition-all sm:my-1 lg:w-vw-1/3 font-sans `}
                >
                  {isOpen ? null : (
                    <div class="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div class="sm:flex sm:items-start">
                        <div class="mx-auto flex-shrink-0 flex items-center justify-center  w-12 h-12 rounded-full bg-green-800  sm:mx-0 sm:h-10 sm:w-10">
                          <i class="material-icons text-white">king_bed</i>
                        </div>
                        <div class=" text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3
                            class="text-lg  mt-1 font-medium text-gray-900"
                            id="modal-headline"
                          >
                            {info.name}
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}

                  <div class={`px-6 py-6`}>
                    {/** Room type */}
                    {isOpen ? null : (
                      <div className="py-3">
                        <label
                          for="room"
                          class="block text-base font-medium text-gray-700 pb-2"
                        >
                          Select room type
                        </label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <div class=" flex items-center">
                            <label for="room" class="sr-only">
                              Select room type
                            </label>
                            <select
                              defaultValue="deluxe"
                              onChange={event => {
                                event.preventDefault()
                                console.log(event?.currentTarget?.value)
                                const currentRoom = event.currentTarget.value
                                setSelectedRoom(currentRoom)
                              }}
                              id="room"
                              name="room"
                              class="  py-2 pl-2 pr-7 border-transparent  outline-none text-gray-500 sm:text-sm rounded-md border-b-0"
                            >
                              {Object.values(rooms).map(option => {
                                return (
                                  <option
                                    key={option.id}
                                    value={option.value}
                                    class="text-base"
                                  >
                                    {option.label}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>

                        <div class="p-2  bg-white border-gray-200 border rounded-lg text-sm flex items-center">
                          <i class="material-icons text-green-800">info</i>
                          <span class="font-light mx-2">
                            {rooms[selectedRoom]?.description || " "}
                          </span>
                        </div>
                      </div>
                    )}

                    {/** Rooming option */}
                    {isOpen ? null : (
                      <div className="py-3">
                        <label
                          for="room"
                          class="block text-base font-medium text-gray-700 pb-2"
                        >
                          Select room option
                        </label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <div class=" flex items-center">
                            <label for="options" class="sr-only">
                              Select room option
                            </label>
                            <select
                              defaultValue="double"
                              onChange={event => {
                                event.preventDefault()
                                const currentOption = event.currentTarget.value
                                setSelectedOption(currentOption)
                              }}
                              id="room"
                              name="room"
                              class="  py-2 pl-2 pr-7 border-transparent  outline-none text-gray-500 sm:text-sm rounded-md border-b-0"
                            >
                              {Object.values(options).map(option => {
                                return (
                                  <option
                                    key={option.id}
                                    value={option.value}
                                    class="text-base"
                                  >
                                    {option.label}
                                  </option>
                                )
                              })}
                            </select>

                            <div className="px-3">
                              {" "}
                              <span className="text-sm">
                                {" "}
                                <b>
                                  {" "}
                                  USD{" "}
                                  {Number(pricing[selectedOption]).toFixed(
                                    2
                                  )}{" "}
                                </b>{" "}
                                per night on <abbr>B&B</abbr> basis
                              </span>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {isOpen ? null : (
                      <div class="p-2 bg-white border-gray-200 border rounded-lg text-sm flex items-center">
                        <i class="material-icons text-green-800">info</i>
                        <span class="font-light mx-2">
                          {" "}
                          {info.name.split(" ")[0]}{" "}
                          {options[selectedOption]?.description || " "}
                        </span>
                      </div>
                    )}

                    {/** Number of rooms */}
                    {isOpen ? null : (
                      <div className="py-3 ">
                        <label
                          for="rooms-number"
                          class="block text-base font-medium text-gray-700 pb-4"
                        >
                          Number of rooms
                        </label>
                        <div className="py-3 w-full px-4 lg:w-7/12">
                          <SliderRange
                            from={1}
                            to={5}
                            onChange={value => setRoomsPerPackage(value)}
                          />
                        </div>
                      </div>
                    )}

                    {/* Stay Dates */}

                    <div class="py-3">
                      <label
                        for="dates"
                        class="block text-base font-medium text-gray-700 pb-2"
                      >
                        Stay dates
                      </label>

                      <DateRangePicker
                        startDatePlaceholderText="Check-in"
                        endDatePlaceholderText="Check-out "
                        orientation={isMobile ? "vertical" : "horizontal"}
                        small
                        startDate={startDate}
                        endDate={endDate}
                        startDateId="Start"
                        endDateId="End"
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onDatesChange={onDatesChange}
                        onFocusChange={focusedInput => {
                          setFocusedInput(focusedInput)
                          setOpen(true)
                        }} // PropTypes.func.isRequired,
                        onClose={() => setOpen(false)}
                      />
                    </div>

                    {/** Add Package Button */}

                    {shouldShowAddPackageButton && (
                      <div className="flex items-center   justify-end">
                        <button
                          onClick={addRoomPackage}
                          className="flex p-2 bg-gray-100 border-gray-400 text-black border rounded-md hover:bg-black hover:text-white focus:outline-none font-medium"
                        >
                          <i class="material-icons">add</i>
                          <div>
                            <span className="text-sm"> Add Package</span>
                          </div>{" "}
                        </button>
                      </div>
                    )}

                    {/** Personal Information */}

                    {Boolean(packages.length) && (
                      <div className="bg-gray-50 my-8 p-4 -mx-6">
                        <div class="pb-1">
                          <label
                            for="names"
                            class="block text-base font-medium text-gray-700 pb-2"
                          >
                            Personal or organization name
                          </label>

                          <input
                            onChange={handleChange}
                            required
                            type="text"
                            name="names"
                            id="names"
                            class=" focus:ring-green-800  focus:ring-2 focus:outline-none  focus:shadow-outline  border-green-800  block  px-2 w-full text-base  font-light ring-gray-300 py-2 ring-1 rounded-md"
                            placeholder="Kate Robertson"
                          />

                          {errors.names && (
                            <ErrorMessage message={errors.names} />
                          )}
                        </div>

                        <div class="py-2">
                          <label
                            for="email"
                            class="block text-base font-medium text-gray-700 pb-2"
                          >
                            Email
                          </label>

                          <input
                            type="email"
                            name="email"
                            id="email"
                            class=" focus:ring-green-800  focus:ring-2 focus:outline-none  focus:shadow-outline  border-green-800  block  px-2 w-full text-base  font-light ring-gray-300 py-2 ring-1 rounded-md"
                            placeholder="kate.robert@gmail.com"
                            onChange={handleChange}
                          />

                          {errors.email && (
                            <ErrorMessage message={errors.email} />
                          )}
                        </div>

                        <div class="py-2">
                          <label
                            for="email"
                            class="block text-base font-medium text-gray-700 pb-2"
                          >
                            Phone Number
                          </label>

                          <input
                            type="phone"
                            name="phone"
                            id="phone"
                            class=" focus:ring-green-800  focus:ring-2 focus:outline-none  focus:shadow-outline  border-green-800  block  px-2 w-full text-base  font-light ring-gray-300 py-2 ring-1 rounded-md"
                            placeholder="+250 782 345 670"
                            onChange={handleChange}
                          />
                        </div>

                        {/** Message */}
                        <div class="py-2">
                          <label
                            for="message"
                            class="block text-base font-medium text-gray-700 pb-2"
                          >
                            Message
                          </label>

                          <textarea
                            name="message"
                            id="message"
                            class=" focus:ring-green-800  focus:ring-2 focus:outline-none  focus:shadow-outline  border-green-800  block  px-2 w-full text-base  font-light ring-gray-300 py-2 ring-1 rounded-md"
                            placeholder="Type  message..."
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )}

                    {/** Reservation Summary */}

                    {Boolean(packages.length) && (
                      <div>
                        <span className="text-lg font-medium">
                          Reservation Summary
                        </span>

                        <div>
                          {packages.map(reservationPackage => {
                            return (
                              <ReservationPackage
                                removePackage={removeRoomPackage}
                                key={reservationPackage.id}
                                {...reservationPackage}
                              />
                            )
                          })}
                        </div>

                        <div className="flex items-end justify-end pt-5">
                          <span className="text-base lg:text-lg font-medium">
                            Reservation Total : USD{" "}
                            {Number(
                              getReservationTotal(packages)
                            ).toLocaleString()}
                            .00
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/** Call to action buttons -Footer */}

                  {Boolean(packages.length) && (
                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      {/** Submission Error */}
                      <div className="py-4">
                        {" "}
                        <span className="text-red-400 text-sm">
                          {" "}
                          {submissionError}
                        </span>
                      </div>
                      <button
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                        type="submit"
                        class={`w-full  ${
                          isSubmitting ? "bg-gray-400" : " bg-green-800"
                        } ${
                          isSubmitting ? "text-gray-700" : "text-white"
                        }  inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 
                         focus:outline-none focus:ring-none sm:ml-3 sm:w-auto sm:text-sm`}
                      >
                        <span class="text-base">
                          {" "}
                          {isSubmitting ? " Submitting..." : "Reserve "}{" "}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Layout>
  )
}

export default ReserveRoom
