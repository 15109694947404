import getRoomInfo from "./getRoomInfo"

const options = {
  double: {
    id: 1,
    value: "double",
    label: "Double",
    description: "bungalow with 1 double bed for 2 person, ideally for couple",
  },

  twin: {
    id: 2,
    value: "twin",
    label: "Twin",
    description:
      "bungalow with 2 Twin bed for 2 person, ideally for people want to share same room",
  },

  single: {
    id: 3,
    value: "single",
    label: "Single",
    description: "bungalow with 1 bed for 1 person",
  },

  triple: {
    id: 4,
    value: "triple",
    label: "Triple",
    description:
      "bungalow with  1 double bed + 1 single bed. The perfect bungalow for family who want to share room with kids",
  },
}

const rooms = {
  deluxe: {
    id: 2,
    value: "deluxe",
    label: "Deluxe Room",
    description:
      "A classical minimalist style suite with high quality materials. Deluxe rooms are recommended for people who like spacious accommodation in reasonable price.",
  },

  "king-studio": {
    id: 1,
    value: "king-studio",
    label: "King Studio Room",
    description:
      "A luxury room with king-size bed, popular with business travelers with large airspace for inside room office and meeting.",
  },
}

const getBungalowInfo = getRoomInfo

export { getRoomInfo, getBungalowInfo, rooms, options }
