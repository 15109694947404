const getRoomInfo = name => {
  switch (name) {
    case "king-studio":
      return {
        id: 1,
        name: "King Studio Room",
        rooms: 3,
        pricing: {
          single: 70,
          double: 80,
          twin: 80,
          triple: 120,
        },
      }

    case "deluxe":
      return {
        id: 2,
        name: "Deluxe",
        rooms: 15,
        pricing: {
          single: 90,
          double: 100,
          twin: 100,
          triple: 140,
        },
      }

    default:
      return {
        id: 2,
        name: "Deluxe",
        rooms: 15,
        pricing: {
          single: 90,
          double: 100,
          twin: 100,
          triple: 140,
        },
      }
  }
}

export default getRoomInfo
